.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    gap: 20px 6px;
}


.page-item,
.page-link {
    display: inline-flex;
    align-items: center;
    border-radius: 30px;
    justify-content: center;
    font-weight: 700;
    font-size: 16px;
    height: 40px;
    width: 40px;
    
}

.active {
    color: black;
    cursor: default !important;
}

.page-item:not(.active)  {
    color: gray;
}
  
.page-item:hover:not(.active) {
    color: green;
}

.disabled-button {
    display: none;
}
